import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

export default function FormTextField({
  name,
  defaultValue = "",
  label,
  rules,
  xs = 12,
  md = 6,
  type,
  control,
  required,
  disabled,
  onChange,
  onKeyDown,
  onKeyUp,
  rows,
  multiline,
  controlProps = {},
}) {
  var rules2 = {};
  if (required) rules2.required = true;

  var inputMode;
  var inputType = type;
  var style;

  switch (type) {
    case "text":
      style = { textAlign: "left" };
      break;
    case "number":
    case "decimal":
      inputType = "number";
      style = { textAlign: "right" };
      break;
    case "currency": // Aggiunto per la valuta
      inputType = "text"; // Manteniamo il tipo "text" per evitare problemi con la formattazione
      style = { textAlign: "right" };
      break;
    default:
      inputMode = type;
  }

  rules2 = { ...rules2, ...rules };

  const controlProps2 = { inputProps: { style, inputMode }, ...controlProps };

  return (
    <Grid item xs={xs} md={md} style={{ marginBottom: 5 }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules2}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            type={inputType}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            InputLabelProps={type && { shrink: true }}
            onChange={(e) => {
              let value = e.target.value;

              // Se è una valuta, rimuoviamo simboli e formattiamo di nuovo
              if (type === "currency") {
                value = value.replace(/[^\d,.-]/g, ""); // Rimuove tutto tranne numeri, virgole e punti
                field.onChange(value); // Salviamo il valore grezzo nel form
              } else {
                field.onChange(e);
              }

              if (onChange) onChange(e);
            }}
            fullWidth
            error={!!fieldState.error}
            label={label}
            required={required}
            disabled={disabled}
            rows={rows}
            multiline={multiline}
            value={
              type === "currency"
                ? new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(field.value || 0)
                : field.value
            } // Formattiamo SOLO in output
            {...controlProps2}
          />
        )}
      />
    </Grid>
  );
}
