import React, { useState, useEffect, useCallback } from 'react';
import api from 'lib/api2';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useForm, Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import ContextCommand from 'components/ContextCommand';
import useNotification from 'components/useNotification';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormTitle from 'components/FormTitle';
import FormCheckbox from 'components/FormCheckbox';
import { rimuoviNull, nullZLSTabella } from 'lib/dati';
import { useNavigate } from "react-router-dom";
import SimpleDataGrid from 'components/SimpleDataGrid';
import { DateTime } from "luxon";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

export default function Strumenti({ setTitle, setContextCommands }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*trigger,*/ getValues, setValue, reset, watch, /*formState: { errors }*/ } = useForm({ mode: 'all' });
	const [isLoaderOpen, setLoaderOpen] = useState(false);
	const [tipiStrumento, setTipiStrumento] = useState([]);
	const [marche, setMarche] = useState([]);
	const [localizzazioni, setLocalizzazioni] = useState([]);
	const [strutture, setStrutture] = useState([]);
	const [colori, setColori] = useState([]);
	const [marca, setMarca] = useState('');
	const [stato, setStato] = useState('');
	const [defaultLocalizzazione, setDefaultLocalizzazione] = useState('');
	const [valorizzazione, setValorizzazione] = useState('');
	const valoreCommerciale = watch('valoreCommerciale');
	const [numeroContratti, setNumeroContratti] = useState(0);

	const onRowClick = (r) => {
		navigate("/contratti/" + r.row.tipoContratto + "/" + r.row.idContratto);
	}

	const caricaContratti = useCallback(() => {
		api.get(`/contratti?strumento=${id}&sort=data`).then((data) => {
			setNumeroContratti(data.length); // Salva il numero di contratti
		});
	}, [id]);

	const columns = [
		{ field: 'idContratto', headerName: 'Numero', width: 80 },
		{ field: 'descrizioneTipoContratto', headerName: 'Tipo', width: 120 },
		{ field: 'data', headerName: 'Data', width: 120, valueFormatter: params => DateTime.fromSQL(params?.value).toLocaleString({ day: '2-digit', month: '2-digit', year: 'numeric' }) },
		{ field: 'nominativo', headerName: 'Nominativo', flex: 1 }
	]

	useEffect(() => {
		if (id) {
			caricaContratti();
		}
	}, [id, caricaContratti]);

	useEffect(() => {
		setLoaderOpen(true);
	}, []);

	/*
	useEffect(() => {
		console.log(valorizzazione);
		if (isNaN(valorizzazione) || valorizzazione==="") {
			setValue('valorizzazione', parseFloat(valoreCommerciale) );
		}
		}, [valorizzazione, setValue]);
	*/

	useEffect(() => {
		setValue('valoreNoleggio', (parseFloat(valoreCommerciale) || 0) * 1.05);
	}, [valoreCommerciale, setValue]);

	useEffect(() => {
		const caricaTabelle = async () => {
			await api.get('/strumenti/tipi').then(data => {
				data.unshift({ idTipoStrumento: "", descrizioneTipoStrumento: "-" });
				setTipiStrumento(data);
			});
			await api.get('/strumenti/marche?sort=descrizioneMarca').then(data => {
				data.unshift({ idMarca: "", descrizioneMarca: "-" });
				setMarche(data);
			});
			await api.get('/strumenti/strutture?sort=descrizioneStruttura').then(data => {
				data.unshift({ idStruttura: "", descrizioneStruttura: "-" });
				setStrutture(data);
			});
			await api.get('/strumenti/colori?sort=descrizioneColore').then(data => {
				data.unshift({ idColore: "", descrizioneColore: "-" });
				setColori(data);
			});
			await api.get('/strumenti/localizzazioni?sort=localizzazione').then(data => {
				setLocalizzazioni(data);
			});
		}
		caricaTabelle();
	}, []);

	useEffect(() => {
		if (id === "new") {
			setValorizzazione(parseFloat(valoreCommerciale).toFixed(2));
		} else {
			const url = "/strumenti/valorizzazione/" + moment().format('YYYY-MM-DD') + "?matricola=" + id;
			api.get(url).then(data => {
				if (data.length === 1) {
					const val = parseFloat(data[0]['valorizzazione']).toFixed(2);
					setValorizzazione(val);
				} else {
					setValorizzazione(5);
				}
			});
		}
	}, [id, valoreCommerciale]);

	const carica = useCallback(id => {
		api.get(`/strumenti/dettagli/${id}`).then(strumenti => {
			if (strumenti.length === 0 && strumenti._action === "e") {
				alert('Errore nel caricamento dei dati');
			} else {
				const strumento = rimuoviNull(strumenti)[0];
				strumento._action = "e";
				reset(strumento);
				api.get('/strumenti/marche?idMarca=' + strumento.marca).then(m => {
					if (m.length > 0) setMarca(m[0].descrizioneMarca);
					else setMarca('');
				});
				api.get('/strumenti/stati?codiceStato=' + strumento.stato).then(s => {
					if (s.length > 0) setStato(s[0].descrizioneStato);
					else setStato('');
				});
				setDefaultLocalizzazione(strumento.localizzazione);
				if (isNaN(valorizzazione) || valorizzazione === "") {
					const val = parseFloat(strumento.valoreAcquisto).toFixed(2);
					setValorizzazione(val);
				}
			}
			setLoaderOpen(false);
		});
	}, [reset, valorizzazione])

	useEffect(() => {
		if (id === 'new') {
			setValue('_action', 'n');
			setLoaderOpen(false);
			// Pianoforte=1
			setValue('tipoStrumento', 1);
		} else {
			carica(id);
		}
	}, [id, setValue, carica]);

	useEffect(() => {
		if (id === 'new') {
			setTitle("Nuovo strumento");
		} else {
			setTitle(getValues('matricola') + " - " + marca + " " + getValues('modello') + " - " + stato);
		}

	}, [id, marca, setTitle, getValues, stato]);

	const salva = useCallback(strumento => {
		delete strumento.descrizioneStato;
		delete strumento.stato;
		const data = nullZLSTabella([strumento]);
		console.log(data);
		api.post("/strumenti", data).then(() => {
			pushNotification("Dati salvati", "success");
			carica(id);
			const url = "/strumenti/valorizzazione/" + moment().format('YYYY-MM-DD') + "?matricola=" + id;
			api.get(url).then(data => {
				if (data.length === 1) {
					const val = parseFloat(data[0]['valorizzazione']).toFixed(2);
					setValorizzazione(val);
				} else {
					setValorizzazione(5);
				}
			});
			navigate("/strumenti/" + strumento.matricola);
		}, async error => {
			// const descrizioneErrore = await error.text();
			// pushNotification("Errore nella creazione: " + descrizioneErrore, "error");
			pushNotification("Errore nella creazione: strumento già presente con la stessa matricola.", "error");
			console.error(error);
		});
	}, [navigate, pushNotification, carica, id]);

	const elimina = useCallback(strumento => {
		var confermaEliminazione = "";
		if (numeroContratti <= 0) {
			confermaEliminazione = window.confirm("Sei sicuro di voler eliminare questo strumento?");
		} else {
			confermaEliminazione = window.alert("Non puoi eliminare questo strumento perchè sono presenti dei contratti.");
		}
		const id = strumento.matricola;
		if (confermaEliminazione) {
			api.delete("/strumenti/" + id, []).then(r => {
				pushNotification("Strumento eliminato", "success");
				navigate("/strumenti/");
			}, async error => {
				pushNotification("Errore nella cancellazione", "error");
				console.error(error);
			});
			return true;
		}
		return false;
	}, [pushNotification, navigate, numeroContratti]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={() => navigate("/strumenti")}>Indietro</ContextCommand>,
			<ContextCommand key="2" icon="floppy" onClick={handleSubmit(data => salva(data))}>Salva</ContextCommand>,
			<ContextCommand key="3" icon="delete" onClick={handleSubmit(data => elimina(data))}>Elimina</ContextCommand>
		]);
	}, [setContextCommands, handleSubmit, navigate, salva, elimina]);

	return (
		<form>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTitle variant="h6">Dettagli</FormTitle>
				<FormTextField xs={12} md={4} control={control} name="matricola" label="Matricola" required rules={{ maxLength: 12 }} disabled={id !== 'new'} />
				<FormTextField xs={12} md={4} control={control} name="descrizioneStato" label="Stato" disabled />
				<FormSelect xs={12} md={4} control={control} name='tipoStrumento' label="Tipo strumento" options={tipiStrumento} valueField='idTipoStrumento' labelField='descrizioneTipoStrumento' />

				<FormSelect xs={12} md={6} control={control} name='marca' label="Marca" options={marche} valueField='idMarca' labelField='descrizioneMarca' />
				<FormTextField xs={12} md={6} control={control} name="modello" label="Modello" />

				<FormSelect xs={12} md={4} control={control} name='struttura' label="Struttura" options={strutture} valueField='idStruttura' labelField='descrizioneStruttura' />
				<FormSelect xs={12} md={4} control={control} name='colore' label="Colore" options={colori} valueField='idColore' labelField='descrizioneColore' />
				<FormTextField xs={12} md={4} control={control} name='annoProduzione' label="Anno di produzione" />

				<FormCheckbox xs={12} md={12 / 5} control={control} name="acqUsato" label="Acquistato usato" />
				<FormCheckbox xs={12} md={12 / 5} control={control} name="cespite" label="Cespite" />
				<FormCheckbox xs={12} md={12 / 5} control={control} name="cespiteNuovo" label="Cespite (sempre nuovo)" />
				<FormCheckbox xs={12} md={12 / 5} control={control} name="acquistatoDaPrivato" label="Acquistato da privato" />
				<FormCheckbox xs={12} md={12 / 5} control={control} name="inRiparazione" label="In riparazione" />


				<FormTextField xs={12} md={2} control={control} type="number" name="valoreCommerciale" label="Valore commerciale" />

				<Grid item xs={12} md={10}>
					<Controller
						name="localizzazione"
						control={control}
						defaultValue={defaultLocalizzazione}
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								freeSolo
								options={localizzazioni.map((option) => option.localizzazione)}
								renderInput={(params) => <TextField {...params} label="Localizzazione" />}
								value={value}
								onChange={(event, newValue) => {
									onChange(newValue || '');
									setDefaultLocalizzazione(newValue || '');
								}}
								onInputChange={(event, newInputValue) => {
									onChange(newInputValue || '');
									setDefaultLocalizzazione(newInputValue || '');
								}}
							/>
						)}
					/>
				</Grid>

				<Accordion elevation={1} style={{ width: '100%' }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: 'rgb(214, 190, 156)' }}>
						<Typography>
							Dati acquisto
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={1} style={{ marginTop: 0 }}>
							{/* <FormTextField xs={12} md={6} control={control} type="number" name="valoreNoleggio" label="Valore del noleggio" /> */}
							<FormTextField xs={12} md={4} control={control} type="number" name="valoreAcquisto" label="Valore di acquisto" />
							<FormTextField xs={12} md={2} control={control} type="number" name="ivaAcquisto" label="IVA di acquisto" />
							<FormTextField xs={12} md={3} control={control} name="fatturaAcquisto" label="Fattura di Acquisto" />
							<FormTextField xs={12} md={3} control={control} name="dataAcquisto" label="Data di acquisto" type="date" />

							<FormTextField xs={12} md={7} control={control} name="fornitore" label="Fornitore" />
							{/*
							<Grid item xs={12} md={5}>
								<TextField value={valorizzazione} type="number" inputProps={{style: { textAlign: 'right' }}} label="Valorizzazione attuale" fullWidth />
							</Grid>
							*/}
							<Grid item xs={12} md={5}>
								<TextField disabled fullWidth inputProps={{ style: { textAlign: 'right' } }} value={valorizzazione} InputLabelProps={{ shrink: true }} label='Valorizzazione attuale' />
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>


				<FormTextField xs={12} md={12} control={control} name="note" label="Note" controlProps={{ multiline: true, rows: 3 }} />

				<FormTitle variant="h6">Elenco contratti</FormTitle>
				<Grid item xs={12} md={12}>
					<SimpleDataGrid
						idField="idContratto"
						getUrl={`/contratti?strumento=${id}&sort=data`}
						columns={columns}
						allowAdd={false}
						allowDelete={false}
						onRowClick={r => onRowClick(r)}
					/>
				</Grid>

			</Grid>
		</form>
	);
}
