import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api2';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useForm, useWatch } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import ContextCommand from 'components/ContextCommand';
import useNotification from 'components/useNotification';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormTitle from 'components/FormTitle';
import FormCheckbox from 'components/FormCheckbox';
import FormCliente from 'components/FormCliente';
import FormBanca from 'components/FormBanca';
import { nullZLSTabella } from 'lib/dati';
import { useNavigate } from "react-router-dom";
import SimpleDataGrid from 'components/SimpleDataGrid';
import useReport from 'components/Report/useReport.js';
import { DateTime } from "luxon";
import cellMultiline from 'components/SimpleDataGrid/cellMultiline';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { CircularProgress } from '@mui/material';

export default function Documenti({ setTitle, setContextCommands }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const { control, handleSubmit, watch, /*trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({ mode: 'all' });
	const cliente = useWatch({ control, name: "cliente" });
	const banca = useWatch({ control, name: "coordinateBancarie" });
	const [righe, setRighe] = useState([]);
	const [isLoaderOpen, setLoaderOpen] = useState(false);
	const [tipiDocumento, setTipiDocumento] = useState([]);
	const [tipoDocumento, setTipoDocumento] = useState([]);
	const [codiciIVA, setCodiciIVA] = useState([]);
	const [indirizziCliente, setIndirizziCliente] = useState([]);
	const [causaliDDT, setCausaliDDT] = useState([]);
	const [vettori, setVettori] = useState([]);
	const [banche, setBanche] = useState([]);
	const pagata = useWatch({ control, name: 'pagata' });
	const codiceIVA = watch('codiceIVA');
	const importoBollo = watch('importoBollo');
	const ddtColli = watch('ddtColli');
	const { openReport } = useReport();
	const [pagamentiMetodi, setPagamentiMetodi] = useState([]);
	const [dialogOpen, setDialogOpen] = useState(false);

	const columnsRighe = [
		// { field: 'idDocumentoRiga', headerName: 'ID', width: 80, type:'number' },
		// { field: 'documento', headerName: 'Documento', width: 80, type:'number' },
		{ field: 'descrizione', headerName: 'Descrizione', width: 350, type: 'string', editable: true, flex: true, ...cellMultiline() },
		{
			field: 'periodoInizio',
			headerName: 'Periodo inizio',
			width: 120,
			editable: true,
			hide: tipoDocumento === 'DDT',
			valueFormatter: params => {
				const dateValue = params?.value;
				if (dateValue && dateValue !== '0000-00-00') {
					return DateTime.fromSQL(dateValue).toLocaleString({ day: '2-digit', month: '2-digit', year: 'numeric' });
				} else {
					return '';
				}
			}
		},
		{
			field: 'periodoFine',
			headerName: 'Periodo fine',
			width: 120,
			editable: true,
			hide: tipoDocumento === 'DDT',
			valueFormatter: params => {
				const dateValue = params?.value;
				if (dateValue && dateValue !== '0000-00-00') {
					return DateTime.fromSQL(dateValue).toLocaleString({ day: '2-digit', month: '2-digit', year: 'numeric' });
				} else {
					return '';
				}
			}
		},
		{ field: 'unitaMisura', headerName: 'UM', width: 80, type: 'string', editable: true },
		{ field: 'quantita', headerName: 'Quantità', width: 80, type: 'number', editable: true },
		{ field: 'importo', headerName: 'Importo', width: 80, type: 'number', editable: true, hide: tipoDocumento === 'DDT', },
	]

	const columnsEsiti = [
		{ field: 'id', headerName: 'ID', width: 60, align: 'right' },
		{ field: 'destinatario', headerName: 'Destinatario', flex: 1, align: 'left' },
		{ field: 'dataOraInvio', headerName: 'Data ed ora', width: 250, type: 'string', valueGetter: params => DateTime.fromSQL(params.value).toFormat('dd/MM/yyyy HH:mm:ss') },
		{ field: 'esito', headerName: 'Esito', width: 200, align: 'left' },
	]

	const esigibilitaIva = [
		{ value: 'I', label: 'Esigibilità immediata' },
		{ value: 'D', label: 'Esigibilità differita' },
		{ value: 'S', label: 'Scissione dei pagamenti (o Split payment)' },
	]

	const porto = [
		{ value: 'Franco', label: 'Franco' },
		{ value: 'Assegnato', label: 'Assegnato' },
	]

	useEffect(() => {
		setLoaderOpen(true);
		api.get('/documenti/tipi').then(data => {
			setTipiDocumento(data);
		});
		api.get('/iva').then(data => {
			setCodiciIVA(data);
		});
		api.get('/causali').then(data => {
			setCausaliDDT(data);
		});
		api.get('/vettori').then(data => {
			setVettori(data);
		});
		api.get('/pagamentiMetodi').then(data => {
			setPagamentiMetodi(data);
		});
		api.get('/banche').then(data => {
			setBanche(data);
		});
	}, []);

	useEffect(() => {
		if (!pagata) {
			// Resetta i campi se 'pagata' è deselezionato
			setValue('dataPagamento', '');
			setValue('metodoPagamento', '');
		}
	}, [pagata, setValue]);

	useEffect(() => {
		if (banca) {
			const bancaSelezionata = banche.find(b => b.descrizioneBanca === banca);
			if (bancaSelezionata) {
				setValue('iban', bancaSelezionata.iban);
			}
		}
	}, [banca, banche, setValue]);

	useEffect(() => {
		var imponibile = 0;
		var colli = 0;
		righe.forEach(r => {
			imponibile += parseFloat(r.importo || 0) * parseFloat(r.quantita || 0);
			colli += parseFloat(r.quantita || 0);
		});
		var aliquotaIva = 0;
		const iva = codiciIVA.find(c => c.codiceIVA === codiceIVA);
		if (iva) {
			aliquotaIva = parseFloat(iva.aliquotaIva);
		}
		const totaleIVA = imponibile * aliquotaIva / 100

		setValue('_imponibile', imponibile.toFixed(2));
		setValue('_totaleIVA', totaleIVA.toFixed(2));
		setValue('_totale', (imponibile + totaleIVA + parseFloat(importoBollo || 0)).toFixed(2));

		setValue('ddtColli', colli);

	}, [righe, codiceIVA, importoBollo, ddtColli, codiciIVA, setValue]);

	useEffect(() => {
		if (cliente) {
			api.get('/clienti/' + cliente + '/indirizzi').then(data => {
				setIndirizziCliente(data.map(d => ({ ...d, indirizzoCompilato: d.descrizioneIndirizzo + ' ' + d.denominazioneIndirizzo + ' - ' + d.via + ', ' + d.civico + ' - ' + d.cap + ' ' + d.localita + ' (' + d.provincia + ')' })));
			});
		}
	}, [cliente]);

	const handleClienteChange = cliente => {
		api.get('/clienti/' + cliente).then(data => {
			if (data.length === 0) {
				setValue('indirizzo', '')
			} else {
				setValue('indirizzo', data[0].indirizzoDestinazione)
			}
		});
	}

	useEffect(() => {
		if (id === 'new') {
			const oggi = DateTime.now().toFormat('yyyy-MM-dd');
			setValue('data', oggi);
			setValue('_action', 'n');
			setValue('importoBollo', '');
			setLoaderOpen(false);
			api.get('/configurazione').then(data => {
				const ivaDefaultObject = data.find(item => item.chiave === 'ivaDefault');
				const ivaDefault = ivaDefaultObject ? ivaDefaultObject.valore : '';
				setValue('codiceIVA', ivaDefault);
			});
		} else {
			api.get(`/documenti/${id}`).then(data => {
				if (data.length !== 1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const documento = data[0];
					setTipoDocumento(documento.tipoDocumento);
					documento._action = "e";
					setTitle(documento.tipoDocumento + " - " + documento.numero + "/" + new Date(documento.data).getFullYear())
					api.get('/clienti/' + documento.cliente + '/indirizzi').then(data => {
						setIndirizziCliente(data.map(d => ({ ...d, indirizzoCompilato: d.via + ', ' + d.civico + ' - ' + d.cap + ' ' + d.localita + ' (' + d.provincia + ')' })));
						// console.log('Documento caricato:', documento);
						reset(documento);
					});
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue, reset, setTitle]);

	const stampa = useCallback(() => {
		var url = "/report/documenti/" + id;
		openReport(url);
	}, [id, openReport]);

	const inviaMailDDT = useCallback(data => {
		setDialogOpen(true);
		api.post("/documenti/" + id + "/inviomailvettore").then(d => {
			pushNotification("Mail inviata", "success");
			api.get(`/documenti/${id}`).then(data => {
				const documento = data[0];
				reset(documento);
			});
			setDialogOpen(false);
		}, error => {
			pushNotification("Errore nell'invio della mail.", "error");
			setDialogOpen(false);
		});
	}, [id, pushNotification, reset]);

	const inviaMailDDTCliente = useCallback(data => {
		if (!window.confirm("Confermare l'invio della mail?"))
			return;
		setDialogOpen(true);
		api.post("/documenti/" + data.idDocumento + "/mail", "").then(d => {
			pushNotification("Mail inviata", "success");
			setDialogOpen(false);
		}, error => {
			pushNotification("Errore nell'invio della mail", "error");
		});
	}, [pushNotification]);

	const elimina = useCallback(() => {
		const feInvioValue = watch('feInvio');
		if (feInvioValue) {
			pushNotification("Impossibile eliminare il documento perché è stato inviata la fattura elettronica.", "error");
			return false;
		}
		const confermaEliminazione = window.confirm("Sei sicuro di voler eliminare questo documento?");
		if (confermaEliminazione) {
			api.delete("/documenti/" + id, []).then(
				(r) => {
					pushNotification("Dati cancellati", "success");
					navigate("/documenti/");
				},
				(error) => {
					pushNotification("Errore nella cancellazione", "error");
				}
			);
			return true;
		}
		return false;
	}, [id, pushNotification, navigate, watch]);

	useEffect(() => {
		const inviaMail = async data => {
			if (!window.confirm("Confermare l'invio della mail?"))
				return;
			setDialogOpen(true);
			api.post("/documenti/" + data.idDocumento + "/mail", "").then(d => {
				pushNotification("Mail inviata", "success");
				setDialogOpen(false);
			}, error => {
				pushNotification("Errore nell'invio della mail", "error");
				setDialogOpen(false);
			});
		}

		const salva = async data => {
			delete data['_imponibile'];
			delete data['_totaleIVA'];
			delete data['_totale'];
			var array = [];
			array.push(data);
			const json = nullZLSTabella(array);
			const righeNuove = [...righe];
			api.post("/documenti", json[0]).then(async idDocumento => {
				if (id === 'new') {
					righeNuove.forEach(riga => {
						riga.documento = idDocumento;
						riga._action = "n";
					});
				}
				await api.post("/documenti/" + (id === 'new' ? idDocumento : id) + "/righe", nullZLSTabella(righeNuove)).then(d => {
					pushNotification("Dati salvati", "success");
				}, error => {
					pushNotification("Errore nel salvataggio delle righe", "error");
				});
				navigate("/documenti/" + idDocumento);
			}, error => {
				pushNotification("Errore nel salvataggio del documento", "error");
			});
		}

		const duplica = async (data) => {
			delete data['_imponibile'];
			delete data['_totaleIVA'];
			delete data['_totale'];
			delete data['idDocumento'];

			delete data['feErrorCode'];
			delete data['feErrorDescription'];
			delete data['feUploadFileName'];
			delete data['feInvio'];

			setValue('tipoDocumento', "");
			setValue('numero', "");
			data._action = "n";
			const dataOdierna = DateTime.now().toISODate();
			setValue('data', dataOdierna);
			setValue('pagata', 0);
			setValue('dataPagamento', "");
			setValue('scadenza', "");
			if (window.confirm("Sei sicuro di voler duplicare questo documento?")) {
				navigate("/documenti/new");
				const righeCopiate = [...righe];
				righeCopiate.forEach(riga => {
					riga.idDocumentoRiga = null;
					riga.documento = "new";
					riga._action = "n";
				});
				pushNotification("Documento duplicato con successo", "success");
			}
		};

		if (tipoDocumento === "DDT") {
			setContextCommands([
				<ContextCommand key="1" icon="backspace" onClick={() => navigate("/documenti")}>Elenco</ContextCommand>,
				<ContextCommand key="2" icon="send" onClick={handleSubmit(data => inviaMailDDT(data))}>Invia mail (vettore)</ContextCommand>,
				<ContextCommand key="3" icon="mail" onClick={handleSubmit(data => inviaMailDDTCliente(data))}>Invia MAIL (cliente)</ContextCommand>,
				<ContextCommand key="4" icon="file" onClick={handleSubmit(data => stampa(data))}>Stampa</ContextCommand>,
				<ContextCommand key="5" icon="delete" onClick={elimina}>Elimina</ContextCommand>,
				<ContextCommand key="6" icon="floppy" onClick={handleSubmit(data => salva(data))}>Salva</ContextCommand>,
			]);
		} else if (tipoDocumento === "TD01") {
			setContextCommands([
				<ContextCommand key="1" icon="backspace" onClick={() => navigate("/documenti")}>Elenco</ContextCommand>,
				<ContextCommand key="2" icon="mail" onClick={handleSubmit(data => inviaMail(data))}>Invia MAIL di cortesia</ContextCommand>,
				<ContextCommand key="3" icon="file" onClick={handleSubmit(data => stampa(data))}>Stampa</ContextCommand>,
				(watch('feInvio') === "" && <ContextCommand key="4" icon="delete" onClick={elimina}>Elimina</ContextCommand>),
				<ContextCommand key="5" icon="plus" onClick={handleSubmit(data => duplica(data))}>Duplica</ContextCommand>,
				(watch('feInvio') === "" || id === "new") && (
					<ContextCommand key="6" icon="floppy" onClick={handleSubmit(data => salva(data))}>
						Salva
					</ContextCommand>
				),
			]);
		} else {
			setContextCommands([
				<ContextCommand key="1" icon="backspace" onClick={() => navigate("/documenti")}>Elenco</ContextCommand>,
				//<ContextCommand key="2" icon="mail" onClick={handleSubmit(data => inviaMail(data))}>Invia MAIL di cortesia</ContextCommand>,
				<ContextCommand key="3" icon="file" onClick={handleSubmit(data => stampa(data))}>Stampa</ContextCommand>,
				<ContextCommand key="4" icon="delete" onClick={elimina}>Elimina</ContextCommand>,
				<ContextCommand key="5" icon="floppy" onClick={handleSubmit(data => salva(data))}>Salva</ContextCommand>,
			]);
		}
	}, [watch, tipoDocumento, setContextCommands, handleSubmit, navigate, stampa, inviaMailDDT, inviaMailDDTCliente, elimina, setValue, pushNotification, righe, id]);


	return (
		<form>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTitle variant="h6">Intestazione</FormTitle>
				{/*
				<FormTextField xs={2} md={1} control={control} name="idDocumento" label="ID" disabled={true} />
				*/}
				<FormSelect xs={12} md={7} control={control} name='tipoDocumento' label="Tipo Documento" options={tipiDocumento} valueField='codiceTipoDocumento' labelField='descrizioneCodiceTipoDocumento' rules={{ required: true }} />
				<FormTextField xs={6} md={2} control={control} name="numero" label="Numero" type="number" />
				<FormTextField xs={6} md={3} control={control} name="data" label="Data" type="date" required />

				<FormCliente xs={12} md={6} control={control} name="cliente" label="Cliente" onSelect={handleClienteChange} required />
				<FormSelect xs={12} md={6} control={control} name='indirizzo' label="Indirizzo" options={indirizziCliente} valueField='idIndirizzo' labelField="indirizzoCompilato" />

				<FormTitle variant="h6">Dati righe documento</FormTitle>
				<Grid item xs={12} >
					<SimpleDataGrid
						upData={setRighe}
						idField="idDocumentoRiga"
						getUrl={id !== 'new' ? "/documenti/" + id + "/righe" : null}
						columns={columnsRighe}
						setContextCommands={() => { }}
						newRowTemplate={{ documento: id }}
						gridProps={{
							getRowHeight: () => 'auto'
						}}
					/>
				</Grid>
				<Grid item xs={12} md={9} ></Grid>
				{tipoDocumento !== "DDT" && <>
					<FormTextField xs={12} md={3} control={control} name="_imponibile" label="Imponibile" type="currency" disabled />

					<FormTitle variant="h6">IVA</FormTitle>
					<FormSelect xs={6} md={3} control={control} name='codiceIVA' label="Codice IVA" options={codiciIVA} valueField='codiceIVA' labelField='descrizioneIva' />
					<FormSelect xs={6} md={3} control={control} name='esigibilitaIva' label="Esigibilità" options={esigibilitaIva} valueField='value' labelField='label' />
					<Grid item xs={12} md={3} ></Grid>
					<FormTextField xs={12} md={3} control={control} name="_totaleIVA" label="IVA" type="currency" disabled />

					<Grid item xs={12} md={9} ></Grid>
					<FormTextField xs={12} md={3} control={control} name="importoBollo" label="Importo bollo" type="currency" controlProps={{ step: 0.01 }} />

					<Grid item xs={12} md={9} ></Grid>
					<FormTextField xs={12} md={3} control={control} name="_totale" label="Totale" type="currency" disabled />
				</>}

				{tipoDocumento === "DDT" ?
					<>
						<FormTitle variant="h6">Dati DDT</FormTitle>
						<FormTextField xs={12} md={10} control={control} name="ddtNota" label="Nota bene" />
						<FormCheckbox xs={12} md={2} control={control} name="annullato" label="Annullato?" />




						<FormSelect xs={12} md={12} control={control} name='ddtCausale' label="Causale del trasporto" options={causaliDDT} valueField='idCausale' labelField='descrizioneCausale' />

						<FormTextField xs={12} md={2} control={control} name="ddtMezzo" label="Mezzo" defaultValue="Vettore" />
						<FormSelect xs={12} md={10} control={control} name='ddtVettore' label="Vettore" options={vettori} valueField='idVettore' labelField='denominazioneVettore' />

						<FormTextField xs={6} md={3} control={control} name="ddtAspetto" label="Aspetto dei beni" />
						<FormTextField xs={6} md={2} control={control} name="ddtColli" label="Colli (n°)" disabled />
						<FormTextField xs={6} md={2} control={control} name="ddtPeso" label="Peso (kg)" type="decimal" />
						<FormSelect xs={6} md={2} control={control} name='ddtPorto' label="Porto" options={porto} valueField='value' labelField='label' />
						<FormTextField
							xs={6}
							md={3}
							control={control}
							name="ddtRitiro"
							label="Data/ora ritiro"
							type="datetime-local"
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								inputProps: {
									min: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"), // Imposta la data minima al valore corrente
								},
							}}
							value={watch("ddtRitiro") ? DateTime.fromISO(watch("ddtRitiro")).toFormat("yyyy-MM-dd'T'HH:mm") : ""}
							onChange={(e) => setValue("ddtRitiro", e.target.value)}
						/>

						<FormTextField xs={6} md={6} control={control} name="ddtEsitoMail" label="Esito invio mail" disabled />
						<FormTextField xs={6} md={6} control={control} name="ddtInvioMail" label="Data invio mail" type="datetime" disabled />
					</>
					:
					<>
						<FormTitle variant="h6">Dati pagamento</FormTitle>
						<FormTextField xs={12} md={3} control={control} name="scadenza" label="Data di scadenza" type="date" />
						<FormCheckbox xs={12} md={2} control={control} name="pagata" label="Pagata?" />
						<FormTextField xs={12} md={3} control={control} name="dataPagamento" label="Data del pagamento" type="date" />
						<FormSelect xs={12} md={4} control={control} name='metodoPagamento' label="Metodo di pagamento" options={pagamentiMetodi} valueField='codice' labelField='descrizione' />

						<FormBanca xs={12} md={7} control={control} name="coordinateBancarie" label="Coordinate Bancarie" />
						<FormTextField xs={12} md={5} control={control} name="iban" label="IBAN" type="text" disabled />

						<FormTitle variant="h6">Dati invio fattura elettronica</FormTitle>
						<FormTextField xs={6} md={3} control={control} name="feInvio" label="Invio" type="datetime-local" disabled />
						<FormTextField xs={6} md={3} control={control} name="feErrorDescription" label="Esito" disabled />
						<FormTextField xs={6} md={3} control={control} name="feErrorCode" label="Errore" disabled />
						<FormTextField xs={6} md={3} control={control} name="feUploadFileName" label="File" disabled />

						<FormTitle variant="h6">Riferimenti DDT</FormTitle>
						<FormTextField xs={6} md={6} control={control} name="ddtRiferimento" label="Riferimento DDT" />
						<FormTextField xs={6} md={6} control={control} name="ddtData" label="Data DDT" type="date" />

						<FormTitle variant="h6">Sezione per fattura elettronica: DatiOrdineAcquisto</FormTitle>
						<FormTextField xs={12} md={2} control={control} name="codiceCommessaConvenzione" label="Codice commessa" />
						<FormTextField xs={6} md={2} control={control} name="numeroOda" label="Numero ODA" />
						<FormTextField xs={6} md={2} control={control} name="dataOda" label="Data ODA" type="date" />
						<FormTextField xs={6} md={3} control={control} name="codiceCIG" label="Codice CIG" />
						<FormTextField xs={6} md={3} control={control} name="codiceCUP" label="Codice CUP" />

						<FormTitle variant="h6">Sezione per fattura elettronica: DatiContratto</FormTitle>
						<FormTextField xs={12} md={2} control={control} name="codiceCommessaContratto" label="Codice commessa" />
						<FormTextField xs={6} md={2} control={control} name="numeroContratto" label="Numero contratto" />
						<FormTextField xs={6} md={2} control={control} name="dataContratto" label="Data contratto" type="date" />
						<FormTextField xs={6} md={3} control={control} name="codiceCIGContratto" label="Codice CIG" />
						<FormTextField xs={6} md={3} control={control} name="codiceCUPContratto" label="Codice CUP" />

						<FormTitle variant="h6">Dati extra</FormTitle>
						<FormTextField xs={12} md={12} control={control} name="fatturaStornata" label="Fattura stornata" type="number" />
					</>
				}
				{id !== 'new' &&
					<>
						<FormTitle variant="h6">Esiti invii mail</FormTitle>
						<Grid item xs={12} >
							<SimpleDataGrid
								idField="id"
								getUrl={"/documenti/" + id + "/mail?sort=dataOraInvio desc"}
								columns={columnsEsiti}
								allowAdd={false}
								allowDelete={false}
								setContextCommands={() => { }}
							/>
						</Grid>
					</>}
			</Grid>
			{/* Dialog con barra di progresso */}
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>Invio della mail in corso...</DialogTitle>
				<DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
					<CircularProgress />
				</DialogContent>
			</Dialog>
		</form>
	);
}