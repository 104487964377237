import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Grid from '@mui/material/Grid';
import FormTitle from 'components/FormTitle';
import SimpleDataGrid from 'components/SimpleDataGrid';
import { DateTime } from "luxon";
import IconButton from '@mui/material/IconButton';

export default function DocumentiClienti({idCliente}) {
	const { id } = useParams();

	const apriDocumento = useCallback ( data => {
		const idDocumento = data.row.idDocumento;
		window.open("/documenti/"+idDocumento);
	}, []);

	const colonneDocumentiClienti = [
		{
			field: 'tipoDocumento',
			headerName: 'Tipo documento',
			editable: false,
			width: 140,
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
			  </div>
			),
		},
		{ field: 'data', headerName: 'Data', width: 120, editable: false, valueFormatter: params => params?.value ? DateTime.fromSQL(params?.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) : '', },
		{
			field: 'numero',
			headerName: 'Numero',
			editable: false,
			width: 100,
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
				<IconButton onClick={() => apriDocumento(params)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
			),
		},
		{
			field: 'fatturaStornata',
			headerName: 'Stornata',
			editable: false,
			width: 100,
			/*
			renderCell: (params) => params.value && (
			  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>{params.value}</span>
				<IconButton onClick={() => apriDocumento(params)}>
				  <OpenInNewIcon />
				</IconButton>
			  </div>
			),
			*/
		},
		{ field: 'annullato', headerName: 'Annullato', width: 90, valueFormatter: params=> params.value==='1'?'Sì':'' },
		{ field: 'pagata', headerName: 'Pagata', width: 80, editable: false, valueFormatter: params => params.value.toString()==='1' ? 'SI' : 'NO' },
		{ field: 'feInvio', headerName: 'Fattura elettronica', width: 180, editable: false, },
		{ field: 'feErrorDescription', headerName: 'Esito', width: 180, editable: false, },
	];

    return 	(			
        <>
            <FormTitle variant="h6">Documenti</FormTitle>
            <Grid item xs={12} >
                <SimpleDataGrid
                    idField="idDocumento"
					getUrl={id!=='new' ? "/documenti?cliente="+idCliente : ""}
                    columns={colonneDocumentiClienti}
                    allowAdd={false}
                    allowDelete={false}
                    gridProps={{
                        disableMultipleRowSelection:true
                    }}
                />
            </Grid>
        </>
    )

} 