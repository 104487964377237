import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import { useForm } from 'react-hook-form';
import api from 'lib/api2';
import FormDateRangePickerSingle from 'components/FormDateRangePickerSingle';
import moment from 'moment';

export default function Filtro({ filtra }) {
    const [defaultValues] = useState({ data_daterange: [moment().startOf('month'), moment().endOf('month')], tipoContratto: [] });
    const { control, handleSubmit } = useForm({ mode: 'all', defaultValues });
    const [tipiContratti, setTipiContratti] = useState([]);
    const optionsBool = [
        { value: '', label: '--Tutti--' },
        { value: '0', label: 'No' },
        { value: '1', label: 'Sì' },
    ]

    useEffect(() => {
        filtra(defaultValues)
    }, [filtra, defaultValues])

    useEffect(() => {
        api.get('/contratti/tipi').then(data => {
            setTipiContratti([{ codice: '', descrizione: '--Tutti--' }, ...data]);
        });
    }, []);

    return (
        <form onSubmit={handleSubmit(filtra)}>
            <Grid container spacing={1}>
                <FormDateRangePickerSingle control={control} xs={12} md={3} label='Data' name='data_daterange' />
                <FormTextField xs={12} md={2} control={control} name="nome_like" label="Nome" />
                <FormTextField xs={12} md={2} control={control} name="cognome_like" label="Cognome" />
                <FormSelect multiple xs={12} md={2} label='Tipo contratto' options={tipiContratti} labelField='descrizione' valueField='codice' control={control} name='tipoContratto' />
                <FormSelect xs={12} md={2} control={control} name='pagata' label="Pagata" options={optionsBool} />

                <Grid item xs={12} md={1}>
                    <Button type="submit" style={{ height: 56 }} size="large" variant="outlined" fullWidth>Filtra</Button>
                </Grid>
            </Grid>
        </form>
    )
}