import React, { useState, useEffect, useCallback } from 'react';
//import { useNavigate } from "react-router-dom";
import ContextCommand from 'components/ContextCommand';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import Filtro from './Filtro';
import useReport from 'components/Report/useReport.js';
import moment from 'moment';
import Elenco from './Elenco';
import qsBuilder from 'lib/qsBuilder';

export default function ClientiElenco({ setTitle, setContextCommands }) {
	//const navigate = useNavigate();
	const apiRef = useGridApiRef();
	const [filtro, setFiltro] = useState({});
	const { openReport } = useReport();
	const [selectedRows, setSelectedRows] = useState([]);

	const stampa = useCallback(() => {
		if (Object.keys(filtro).length === 0) {
			alert("Filtri non impostati. Filtrare e stampare l'elenco.");
			return;
		}
		if (filtro.codiceStato === 'cespiti') filtro.codiceStato = '';
		var url = "/report/strumenti?sort=codiceStato,strumentiMarche.descrizioneMarca,matricola&" + qsBuilder(filtro);
		openReport(url);
	}, [filtro, openReport]);

	const stampaReportClienti = useCallback(() => {
		var url = "/report/strumenti/clienti?sort=strumentiMarche.descrizioneMarca,matricola&";
		// console.log(selectedRows);
		if (selectedRows.length === 0) {
			alert("Strumenti non selezionati. Selezionarli e stampore la proposta.");
			return;
		} else {
			if (selectedRows.length > 0) {
				url += "matricola=" + selectedRows.map(r => encodeURIComponent(r)).join(',');
			} else {
				url += qsBuilder(filtro);;
			}
			openReport(url);
		}
	}, [filtro, openReport, selectedRows]);

	const stampaValorizzazione = useCallback(() => {
		var data = '';
		do {
			const msg = (data ? 'Data ' + data + ' non valida.\n' : '') + 'Inserire da data di valorizzazione:';
			data = window.prompt(msg, moment().format('DD-MM-YYYY'));
		} while (data && !moment(data, 'DD-MM-YYYY').isValid())
		if (!data) return;
		const qs = { ...qsBuilder(filtro) };
		delete (qs.codiceStato);
		const dataValorizzazione = moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD');
		var url = "/report/strumentiValorizzazione/" + dataValorizzazione + "?" + qs;
		openReport(url);
	}, [openReport, filtro]);

	useEffect(() => {
		setTitle('Strumenti');
		const contextCommands = [];
		contextCommands.push(<ContextCommand key="1" icon="currency-eur" onClick={() => stampaValorizzazione()}>Valorazzazione</ContextCommand>);
		contextCommands.push(<ContextCommand key="2" icon="file-document" onClick={() => stampa()}>Elenco</ContextCommand>);
		contextCommands.push(<ContextCommand key="3" icon="file-account" onClick={() => stampaReportClienti()}>Proposta clienti</ContextCommand>);
		contextCommands.push(<ContextCommand key="4" icon="plus" onClick={() => window.open("/strumenti/new")}>Inserisci</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setTitle, setContextCommands, stampaValorizzazione, stampa, stampaReportClienti]);

	return (
		<div style={{ marginBottom: 50, height: '80vh' }}>
			<Filtro filtra={setFiltro} />
			<Elenco queryString={qsBuilder(filtro)} apiRef={apiRef} setSelectedRows={setSelectedRows} />
		</div>
	);
}