
import React, { useState, useEffect, useCallback } from 'react';
import api from 'lib/api2';
import Filtro from './Filtro';
import qsBuilder from 'lib/qsBuilder';
import Elenco from './Elenco';
import { useNavigate } from "react-router-dom";
import ContextCommand from 'components/ContextCommand';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { removeTags } from 'lib/dati';
import useNotification from 'components/useNotification';
import { LinearProgress, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';

export default function DocumentiElenco({setTitle, setContextCommands}) {
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const apiRef = useGridApiRef();
	const [ queryString, setQueryString ] = useState("");
	const [ selectedRows, setSelectedRows ] = useState([]);
	const [progress, setProgress] = useState(0);
	const [dialogOpen, setDialogOpen] = useState(false);

	const inviaMail = useCallback ( () => {
		if (selectedRows.length === 0) {
            pushNotification("Seleziona almeno un documento per inviare la mail.", "warning");
            return;
        }
		if (!window.confirm("Confermare l'invio delle mail?"))
			return;
		setDialogOpen(true);
        setProgress(0);
		const total = selectedRows.length;
        let completed = 0;
		selectedRows.forEach((row) => {
            api.post("/documenti/" + row.idDocumento + "/mail", "")
                .then((d) => {
                    pushNotification("Mail inviata", "success");
                })
                .catch((error) => {
                    pushNotification("Errore nell'invio delle mail", "error");
                })
                .finally(() => {
                    completed += 1;
                    setProgress((completed / total) * 100);
                    // Termina il progresso quando tutti i documenti sono stati processati
                    if (completed === total) {
						setDialogOpen(false);
                    }
                });
        });
	}, [selectedRows, pushNotification]);

	const inviaFE = useCallback(() => {
		if (!window.confirm("Confermare l'invio dei documenti XML?")) {
			return;
		}
		selectedRows.forEach((row) => {
			if (!row.feInvio) {
				api.post("/fattura/" + row.idDocumento + "/invio", "").then(r => {
					if (r.errorCode && r.errorCode!=='0000') {
						pushNotification("Fattura (ID "+row.idDocumento+") non inviata. Errore: " + removeTags(r.errorDescription), "error");
					} else {
						pushNotification("Fattura (ID "+row.idDocumento+") inviata.", "success");
						navigate("/documenti");
					}
				});
			} else {
				pushNotification("Fattura (ID "+row.idDocumento+") è già stata inviata.", "warning");
			}
		});
	}, [selectedRows, pushNotification, navigate]);
	
	useEffect(() => {
		setTitle('Documenti');
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="mail" onClick={ ()=>inviaMail() }>Invia MAIL di cortesia</ContextCommand>);
		contextCommands.push(<ContextCommand key="2" icon="file-upload-outline" onClick={ ()=>inviaFE() }>Invia FE</ContextCommand>);
		contextCommands.push(<ContextCommand key="3" icon="plus" onClick={ ()=>window.open("/documenti/new") }>Inserisci</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setTitle, setContextCommands, inviaMail, inviaFE]);

	const filtra=useCallback(filtro=>{
		setQueryString(qsBuilder(filtro));
	},[]);
	
	return ( 
		<div style={{marginBottom:50, height:'85vh'}}>
			<Filtro filtra={filtra} />
			<Elenco queryString={queryString} apiRef={apiRef} setSelectedRows={setSelectedRows} />
			{/* Dialog con barra di progresso */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Invio delle mail in corso...</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </DialogContent>
            </Dialog>
		</div>
	);
}